import "core-js/modules/es.array.push.js";
import { Icon } from '@clubhouse/shapes-ds';
import moment from 'moment';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { DATE_FORMAT } from '@clubhouse/shared/constants';
import { capitalize } from '@clubhouse/shared/utils';
import OrganizationProfileModel from 'app/client/core/js/models/organizationProfile'; // eslint-disable-line import/no-restricted-paths
import PermissionModel from 'app/client/core/js/models/permission'; // eslint-disable-line import/no-restricted-paths
import { DisabledOrgReasons } from 'components/organizations/DisabledOrgReasons';
import { getFromOrg } from 'data/entity/company';
import { getOrgProfileForLoggedInUser, isOrgDisabled, useOrganization } from 'data/entity/organization';
import { getDefaultEmailAddress, getDefaultPermissionFromProfile, getEmailAddressById, getLoggedInUserPermission } from 'data/entity/user';
import { start as startExport } from 'utils/exportData';
import { openHelp } from 'utils/help';
import { generatePathWithSlug } from 'utils/navigation';
import { DragHandle } from '../shared/DragHandle';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const openHelpSidebar = e => {
  e.preventDefault();
  openHelp();
};
export function Workspace({
  id
}) {
  const {
    org
  } = useOrganization(id);
  const profile = getOrgProfileForLoggedInUser(id);
  const permission = getLoggedInUserPermission(id);
  const isDisabled = isOrgDisabled(id);
  const isDefault = profile.default && permission.default;
  const isUserDisabled = permission.disabled;
  const email = getEmailAddressById(profile.email_id) ?? getDefaultEmailAddress();
  const {
    addToast
  } = useToast();
  const onExportStories = e => {
    e.preventDefault();
    startExport({
      workspace2_id: org.id
    }, {
      company_id: getFromOrg(org),
      organization_id: org.id
    });
  };
  const onMakeDefault = async () => {
    const orgProfile = OrganizationProfileModel.getOrgProfileForLoggedInUser(org.id);
    const fns = [];
    if (OrganizationProfileModel.getDefault()?.id !== orgProfile.id) {
      fns.push(cb => OrganizationProfileModel.setDefault(org.id, cb));
    }
    if (getDefaultPermissionFromProfile(orgProfile)?.id !== permission.id) {
      fns.push(cb => PermissionModel.setDefault(permission, cb));
    }
    if (fns.length) {
      try {
        await Promise.all(fns.map(fn => new Promise((resolve, reject) => {
          fn(err => {
            if (err) reject(err);else resolve();
          });
        })));
      } catch {
        addToast({
          kind: 'warning',
          timeout: 5000,
          Content: () => _jsx(ToastText, {
            children: "Unable to set default workspace."
          })
        });
      }
    }
  };
  return _jsxs("div", {
    className: `org ${isDisabled || isUserDisabled ? 'disabled' : ''}`,
    children: [_jsx("div", {
      className: "title permission",
      children: isDisabled || isUserDisabled ? _jsxs(_Fragment, {
        children: [_jsx("h3", {
          children: org.name
        }), profile.company.locked_out ? _jsx("span", {
          className: "tagged disabled",
          children: _jsxs("span", {
            className: "align-center",
            children: [_jsx(DeprecatedIconAdapter, {
              fill: "currentColor",
              width: 12,
              children: _jsx(Icon, {
                icon: "Lock"
              })
            }), ' ', "Locked"]
          })
        }) : isDisabled ? _jsx("span", {
          className: "tagged disabled",
          children: _jsxs("span", {
            className: "align-center",
            children: [_jsx(DeprecatedIconAdapter, {
              fill: "currentColor",
              width: 12,
              children: _jsx(Icon, {
                icon: "Lock"
              })
            }), ' ', "Disabled"]
          })
        }) : isUserDisabled ? _jsx("span", {
          className: "tagged disabled",
          children: _jsxs("span", {
            className: "align-center",
            children: [_jsx(DeprecatedIconAdapter, {
              fill: "currentColor",
              width: 12,
              children: _jsx(Icon, {
                icon: "Lock"
              })
            }), ' ', "User Disabled"]
          })
        }) : null]
      }) : _jsxs(_Fragment, {
        children: [_jsx("h3", {
          children: _jsx("a", {
            href: `/${org.url_slug}/`,
            children: org.name
          })
        }), _jsx("span", {
          className: "tagged blue",
          children: "Active"
        }), isDefault ? _jsx("span", {
          className: "tagged orange",
          children: "Default"
        }) : _jsx("button", {
          className: "action micro flat-white default-action",
          onClick: onMakeDefault,
          children: "Set as default"
        }), _jsx("span", {
          className: "fa fa-star fa-spin spinner"
        }), _jsx(DragHandle, {
          isWorkspace: true
        }), _jsx("div", {
          className: "right-panel",
          children: _jsxs("a", {
            href: "#",
            onClick: onExportStories,
            className: "align-center",
            children: [_jsx(DeprecatedIconAdapter, {
              fill: "currentColor",
              width: 16,
              children: _jsx(Icon, {
                icon: "Export"
              })
            }), ' ', "Export all Stories as CSV"]
          })
        })]
      })
    }), _jsxs("div", {
      className: "details",
      children: [_jsxs("ul", {
        className: "info",
        children: [_jsxs("li", {
          children: [_jsx("strong", {
            children: "URL:"
          }), ' ', _jsxs("a", {
            href: generatePathWithSlug('/:slug/', {
              slug: org.url_slug
            }),
            className: "url-slug",
            children: ["https://", BRAND.DOMAIN_APP_WEBSITE, "/", org.url_slug]
          })]
        }), _jsxs("li", {
          children: [_jsx("strong", {
            children: "Your Role:"
          }), " ", capitalize(permission.role)]
        }), _jsxs("li", {
          children: [_jsx("strong", {
            children: "Primary Email:"
          }), " ", email]
        }), _jsxs("li", {
          children: [_jsx("strong", {
            children: "Workspace Created:"
          }), " ", moment(org.created_at).format(DATE_FORMAT.SHORT_DATE)]
        })]
      }), profile.company.locked_out ? _jsx("ul", {
        className: "reasons",
        children: _jsxs("li", {
          children: ["This workspace has been locked. Please", ' ', _jsx("a", {
            href: "#",
            onClick: openHelpSidebar,
            children: "contact us"
          }), ' ', "if you want to regain access."]
        })
      }) : isDisabled ? _jsx("ul", {
        className: "reasons",
        children: _jsx(DisabledOrgReasons, {
          orgId: id
        })
      }) : isUserDisabled ? _jsx("ul", {
        className: "reasons",
        children: _jsx("li", {
          children: "You have been disabled in this workspace. To be re-enabled, please contact one of the admins on this account."
        })
      }) : null]
    })]
  });
}
Workspace.displayName = "Workspace";