import Logo from '@clubhouse/assets/png/third-party-logos/spikesh_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function SpikeSh() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.SPIKE_SH
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Spike.sh"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Spike.sh Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://docs.spike.sh/collaboration/task-management-integrations/shortcut",
          children: "Spike.sh"
        }), ' ', "alerts you when things go wrong in production. Create ", BRAND.NAME, " stories with incident details."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://www.shortcut.com/integrations/spike-sh",
          children: "Learn more"
        }), " on how the Spike.sh integration works in ", BRAND.NAME, "."]
      })]
    })]
  });
}
SpikeSh.displayName = "SpikeSh";