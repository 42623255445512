import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { ComboboxSelect } from '@clubhouse/shared/components/ComboboxSelect';
import { useEntityStateFilter } from './utils';
import { getById as getEpicById, useEpics } from 'data/entity/epic';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import { getStateTypes, useEpicStates } from 'data/entity/epicState';
import orderBy from 'lodash/orderBy';
import { groupBy } from '@clubhouse/shared/utils';
import { useMemo, useState } from 'react';
import { EpicIcon } from '@clubhouse/shared/components/EpicIcon';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const filterEpicsByTeamScope = epics => {
  const teamScopeId = getTeamScopedCollectionizeId();
  return teamScopeId ? epics.filter(epic => epic.group_id === teamScopeId) : epics;
};
const Epics = ({
  activeId
}) => {
  const [query, setQuery] = useState('');
  const {
    epics: allEpics
  } = useEpics({
    archived: false
  });
  const {
    epicStates
  } = useEpicStates();
  const items = useMemo(() => {
    const epics = filterEpicsByTeamScope(allEpics);
    const epicsByState = groupBy(epics, e => e.epic_state_id);
    const statesByType = groupBy(epicStates, s => s.type);
    return getStateTypes().reduce((acc, type) => {
      const states = statesByType.get(type) || [];
      const stateItems = states.reduce((acc, state) => {
        const epicsInState = (epicsByState.get(state.id) || []).filter(e => e.name.toLowerCase().includes(query.toLowerCase()));
        if (epicsInState.length) {
          acc.push('hr');
          acc.push(state.name);
          return acc.concat(orderBy(epicsInState, e => e.name.toLowerCase()));
        }
        return acc;
      }, []);
      return acc.concat(stateItems);
    }, []);
  }, [query, allEpics, epicStates]);
  return _jsxs(_Fragment, {
    children: [_jsx(ComboboxSelect.Input, {
      onSearch: setQuery
    }), _jsxs(ComboboxSelect.List, {
      children: [_jsx(ComboboxSelect.Option, {
        value: '',
        children: "None"
      }), items.map((item, i) => {
        if (item === 'hr') return _jsx(ComboboxSelect.Divider, {}, `divider-${i}`);else if (typeof item === 'string') return _jsx(ComboboxSelect.LabelItem, {
          children: _jsx(Emojify, {
            children: item
          })
        }, item);else return _jsxs(ComboboxSelect.Option, {
          value: String(item.id),
          isSelected: String(item.id) === activeId,
          children: [_jsx(EpicIcon, {
            epic: item,
            size: 16
          }), _jsx(Emojify, {
            children: item.name
          })]
        }, item.id);
      })]
    })]
  });
};
export function EpicSelector() {
  let label = 'None';
  const [entityStateFilter, setEntityStateFilter] = useEntityStateFilter();
  if (entityStateFilter?.epicId) {
    const epic = getEpicById(entityStateFilter.epicId);
    if (epic) label = epic.name;
  }
  return _jsxs(ComboboxSelect, {
    onChange: epicId => {
      setEntityStateFilter({
        epicId: epicId ? Number(epicId) : null
      });
    },
    children: [_jsx(ComboboxSelect.Trigger, {
      children: _jsxs("button", {
        className: "action mini light-gray",
        children: [_jsx(Emojify, {
          children: label
        }), " ", _jsx("span", {
          className: "fa fa-caret-down"
        })]
      })
    }), _jsx(ComboboxSelect.Menu, {
      children: _jsx(Epics, {
        activeId: String(entityStateFilter?.epicId)
      })
    })]
  });
}
EpicSelector.displayName = "EpicSelector";