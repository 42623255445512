import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.some.js";
import { Icon } from '@clubhouse/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Nouns } from '@clubhouse/shared/constants';
import { pluralizeNoun } from '@clubhouse/shared/utils';
import { useCompany } from 'data/entity/company';
import { getLoggedInUserPermission } from 'data/entity/user';
import { adminOnly, ownerOnly } from 'utils/is';
import { DragHandle } from '../shared/DragHandle';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function CompanyInfo({
  id
}) {
  const company = useCompany(id);
  const workspaceCount = company.organizations.length;
  const isLockedOut = company.locked_out;
  const isDisabled = company.disabled;
  const isOwner = company.organizations.some(org => ownerOnly(getLoggedInUserPermission(org.id)));
  const isAdmin = company.organizations.some(org => adminOnly(getLoggedInUserPermission(org.id)));
  const spaceTerm = pluralizeNoun(Nouns.Workspace, workspaceCount);
  return _jsxs("div", {
    className: "company-info",
    children: [_jsxs("div", {
      className: "company-sort-actions top-level",
      children: [_jsx(DragHandle, {}), _jsx("span", {
        className: "tagged orange",
        children: "Organization"
      })]
    }), _jsx("h2", {
      children: company.name
    }), _jsxs("div", {
      className: "company-management-actions",
      children: [isLockedOut ? _jsx("span", {
        className: "tagged disabled",
        children: _jsxs("span", {
          className: "align-center",
          children: [_jsx(DeprecatedIconAdapter, {
            fill: "currentColor",
            width: 12,
            children: _jsx(Icon, {
              icon: "Lock"
            })
          }), ' ', "Locked"]
        })
      }) : isDisabled ? _jsx("span", {
        className: "tagged disabled",
        children: _jsxs("span", {
          className: "align-center",
          children: [_jsx(DeprecatedIconAdapter, {
            fill: "currentColor",
            width: 12,
            children: _jsx(Icon, {
              icon: "Lock"
            })
          }), ' ', "Disabled"]
        })
      }) : null, !isLockedOut && _jsxs(_Fragment, {
        children: [!isDisabled && (isOwner || isAdmin) && _jsx("a", {
          href: `/signup?org=${id}`,
          className: "action micro white add-org-to-company",
          children: _jsxs("span", {
            className: "align-center",
            children: [_jsx(DeprecatedIconAdapter, {
              width: 14,
              fill: "currentColor",
              children: _jsx(Icon, {
                icon: "Add"
              })
            }), ' ', "Add Workspace to Organization"]
          })
        }), isOwner ? _jsx("a", {
          href: `/organizations/${company.slug}/manage`,
          className: "action micro white manage-company",
          children: _jsxs("span", {
            className: "align-center",
            children: [_jsx(DeprecatedIconAdapter, {
              width: 14,
              children: _jsx(Icon, {
                icon: "Edit"
              })
            }), ' ', "Manage Organization and ", spaceTerm]
          })
        }) : isAdmin && !isDisabled ? _jsx("a", {
          href: `/organizations/${company.slug}/manage`,
          className: "action micro white manage-company",
          children: _jsxs("span", {
            className: "align-center",
            children: [_jsx(DeprecatedIconAdapter, {
              width: 14,
              children: _jsx(Icon, {
                icon: "Edit"
              })
            }), ' ', "Manage ", spaceTerm]
          })
        }) : null]
      })]
    })]
  });
}
CompanyInfo.displayName = "CompanyInfo";