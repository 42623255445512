import { QueryStoryArchivedMessageDocument } from "../../../datalayer/__generated_graphql_types__/graphql";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
import StoryController from 'app/client/core/js/controllers/story';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Story'], StoryController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { gql } from '@clubhouse/datalayer';
import { FEATURE_TOGGLES, useVariation } from '@clubhouse/feature-toggles';
import { Nouns } from '@clubhouse/shared/constants';
import { ComponentErrorBoundary } from 'components/shared/ComponentErrorBoundary';
import { useQuery } from 'gql';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const QUERY_STORY_ARCHIVED_MESSAGE = QueryStoryArchivedMessageDocument;
function StoryArchivedMessageInternal({
  storyGlobalId
}) {
  const isSubtasksEnabled = useVariation(FEATURE_TOGGLES.ENABLE_SUBTASKS).value;
  const {
    data
  } = useQuery(QUERY_STORY_ARCHIVED_MESSAGE, {
    variables: {
      storyId: storyGlobalId
    }
  });
  if (data?.node?.__typename !== 'Story') {
    return;
  }
  const Text = data.node.parentStory && isSubtasksEnabled ? Nouns.Subtask : Nouns.Story;
  return _jsxs("div", {
    className: "story-archived",
    "data-controller": "Story",
    children: [_jsxs("div", {
      className: "archive-actions",
      children: [_jsx("a", {
        href: "#",
        className: "action mini white",
        "data-on-click": "unarchiveStory",
        children: "Unarchive"
      }), _jsx("a", {
        href: "#",
        className: "action mini red",
        "data-on-click": "deleteStory",
        children: "Delete"
      })]
    }), _jsxs("h3", {
      children: [_jsx("span", {
        className: "fa fa-archive"
      }), " This ", Text.singular, " is archived."]
    }), _jsxs("p", {
      children: ["Archived ", Text.plural, " don't appear on the Stories page and aren't included in any metrics, but can be viewed directly using their permalink. We recommend that you only archive ", Text.plural, " you don't end up doing. (", _jsx("a", {
        href: KNOWLEDGE_BASE_SLUGS.ARCHIVING_FAQ,
        target: "_blank",
        rel: "noreferrer",
        children: "Here's why."
      }), ")"]
    })]
  });
}
StoryArchivedMessageInternal.displayName = "StoryArchivedMessageInternal";
export function StoryArchivedMessage(props) {
  if (!props.isArchived) {
    return;
  }
  return _jsx(ComponentErrorBoundary, {
    componentName: "StoryArchivedMessage",
    children: _jsx(StoryArchivedMessageInternal, {
      ...props
    })
  });
}
StoryArchivedMessage.displayName = "StoryArchivedMessage";