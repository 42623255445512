import { DynamicConfigEntityFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { DynamicConfigInfoFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useCallback } from 'react';
import { useSearchParams } from 'utils/navigation';
export const DynamicConfigFragment = DynamicConfigInfoFragmentDoc;
export const DynamicConfigEntity = DynamicConfigEntityFragmentDoc;
const SPACE_CONFIG_OVERRIDE_SEARCH_PARAMS = ['workflow', 'view_type', 'group_by'];
export const useSpaceConfigOverrideSearchParams = () => {
  const {
    params: rawParams,
    setSearchParams
  } = useSearchParams(SPACE_CONFIG_OVERRIDE_SEARCH_PARAMS);
  const setWorkflow = useCallback(workflow => setSearchParams({
    workflow
  }), [setSearchParams]);
  const setViewType = useCallback(view_type => setSearchParams({
    view_type,
    group_by: ''
  }), [setSearchParams]);
  const setGroupBy = useCallback(group_by => setSearchParams({
    group_by
  }), [setSearchParams]);
  return {
    workflow: String(rawParams.workflow) || undefined,
    viewType: String(rawParams.view_type) || undefined,
    groupBy: String(rawParams.group_by) || undefined,
    setWorkflow,
    setViewType,
    setGroupBy,
    setSearchParams
  };
};
export const getUrlParamsFromSpace = space => {
  const params = {
    id: space?.publicId ? String(space.publicId) : undefined,
    spaceOrEntityType: 'space',
    searchParams: {}
  };
  if (!space) return params;
  const {
    dynamicConfig
  } = space;
  if (dynamicConfig) {
    if (dynamicConfig.viewType) params.searchParams['view_type'] = dynamicConfig.viewType;
    if (dynamicConfig.workflowValue) params.searchParams['workflow'] = dynamicConfig.workflowValue;
    if (dynamicConfig.tableGroupBySelectValue) params.searchParams['group_by'] = dynamicConfig.tableGroupBySelectValue;
    switch (dynamicConfig.underlyingEntity.__typename) {
      case 'Epic':
        params.id = String(dynamicConfig.underlyingEntity.publicId);
        params.spaceOrEntityType = 'epic';
        break;
      default:
        throw new Error('unsupported Dynamic Space entity');
    }
  }
  return params;
};
export const getIdParamFromSpace = space => getUrlParamsFromSpace(space).id;
export const getEntityTypeParamFromSpace = space => getUrlParamsFromSpace(space).spaceOrEntityType;
export const getSpaceData = d => {
  const dynamicSpace = d?.workspace2?.storiesPage?.dynamicSpace;
  const space = d?.workspace2?.storiesPage?.space;
  return dynamicSpace && dynamicSpace.__typename === 'Space' ? dynamicSpace : space;
};