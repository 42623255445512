import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { FilterStateProvider } from 'components/gql/filters/FilterStateProvider';
import { TablePaginationConfig } from 'components/gql/pagination/TablePagination';
import { ListPageLayout } from 'components/layout/ListPageLayout';
import { TableColumnSelectFilterField } from 'components/shared/TableColumnSelectFilterField';
import { TableStateProviderWithAppState } from 'components/shared/TableStateProviderWithAppState';
import { useTeamScopedApplicationStateKey } from 'components/team-navigation';
import { TeamScopedPageTitle } from 'components/team-navigation/components/TeamScopedPageTitle';
import { GraphQlPage } from 'gql/components/GraphQlPage';
import { useIsEmptyWorkspace } from 'pages/epics-apollo/components/useIsEmptyWorkspace';
import { ColumnPaginationConfig } from 'pages/epics-apollo/entities/epics/components/columns/EpicsColumn/components/ColumnPagination';
import { SORT_BY_PARAM_NAME, SORT_DIR_PARAM_NAME } from 'pages/epics-apollo/hooks/useTableSortUrlState';
import { useAutoQuickstartComplete } from 'utils/quickstart';
import { EpicsPageFilterConfig } from '../../../components/filters/filterConfig';
import { EpicsFilters, EpicsQueryFilters } from './EpicsFilters';
import { EpicsGroupByConfig, EpicsGroupBySelect } from './EpicsGroupBySelect';
import { EpicsViewConfig, EpicsViewSelector, useEpicsView } from './EpicsViewSelector';
import { TranslateEpicsPageSearchParams } from './TranslateEpicsPageSearchParams';
import { EpicsColumns } from './columns/EpicsColumns';
import { EpicsSelectedColumnConfig } from './columns/hooks/useSelectedColumn';
import { EpicsTableView } from './table/EpicsTableView';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const pageName = 'epics-apollo';
const applicationFilterStateKey = `${pageName}.epicsFilter`;
const persistedSearchParamKeys = [...Object.values(EpicsPageFilterConfig).map(({
  paramName
}) => paramName), EpicsGroupByConfig.paramName, EpicsViewConfig.paramName, TablePaginationConfig.paramName, EpicsSelectedColumnConfig.paramName, SORT_BY_PARAM_NAME, SORT_DIR_PARAM_NAME];
const clearOnFilterChange = [TablePaginationConfig.paramName, ColumnPaginationConfig.unstarted.paramName, ColumnPaginationConfig.started.paramName, ColumnPaginationConfig.done.paramName];
const DISABLED_COLUMNS_BY_DEFAULT = ['currentHealthStatus'];
const EpicsPageView = () => {
  const view = useEpicsView();
  const [isEmpty] = useIsEmptyWorkspace();
  return _jsx(TableStateProviderWithAppState, {
    appStateKeyPrefix: pageName,
    defaultDisabledColumns: DISABLED_COLUMNS_BY_DEFAULT,
    children: _jsx(FilterStateProvider, {
      clearOnFilterChange: clearOnFilterChange,
      children: _jsx(ListPageLayout, {
        title: !isEmpty && _jsx(TeamScopedPageTitle, {
          title: "Epics"
        }),
        filters: !isEmpty && _jsx(EpicsFilters, {}),
        query: !isEmpty && _jsx(EpicsQueryFilters, {}),
        viewSettings: !isEmpty && _jsxs(_Fragment, {
          children: [view === 'table' && _jsxs(_Fragment, {
            children: [_jsx(EpicsGroupBySelect, {}), _jsx(TableColumnSelectFilterField, {})]
          }), _jsx(EpicsViewSelector, {})]
        }),
        content: view === 'table' ? _jsx(EpicsTableView, {}) : _jsx(EpicsColumns, {})
      })
    })
  });
};
EpicsPageView.displayName = "EpicsPageView";
export const EpicsPage = ({
  renderId
}) => {
  useAutoQuickstartComplete('visit-epics');
  const key = useTeamScopedApplicationStateKey(applicationFilterStateKey);
  return _jsx(GraphQlPage, {
    pageName: pageName,
    applicationFilterStateKey: key,
    persistedSearchParamKeys: persistedSearchParamKeys,
    TranslateSearchParams: TranslateEpicsPageSearchParams,
    renderId: renderId,
    children: _jsx(EpicsPageView, {})
  });
};
EpicsPage.displayName = "EpicsPage";