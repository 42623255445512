import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { QueryJoinTeamItemsDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { emptyArray } from '@clubhouse/shared/utils';
import { useQuery } from 'gql';
import { useCurrentPermissionIdQuery } from 'gql/queries/useWorkspaceCurrentPermissionIdQuery';
export const QUERY_JOIN_TEAM_ITEMS = QueryJoinTeamItemsDocument;
export const useJoinTeamItems = ({
  query,
  currentPermission
}) => {
  const slug = currentPermission.workspace2.url_slug;
  const lowercaseQuery = query.trim().toLowerCase();
  const permissionId = useCurrentPermissionIdQuery(slug);
  const {
    data,
    previousData,
    error
  } = useQuery(QUERY_JOIN_TEAM_ITEMS, {
    skip: !permissionId,
    variables: {
      workspaceSlug: slug,
      teamInput: {
        currentPermissionId: permissionId,
        fuzzyName: lowercaseQuery,
        selectedIds: emptyArray
      },
      membersInput: {
        where: {
          valid: true
        },
        pagination: {
          limit: 5,
          offset: 0
        },
        orderBy: [{
          value: 'displayName',
          direction: 'ascending'
        }]
      }
    }
  });
  const currentData = data || previousData;
  const {
    teamItems,
    numAssignedTeams
  } = useMemo(() => {
    let numAssignedTeams = 0;
    const teamItems = (currentData?.workspace2?.teamSelectOptions?.edges || []).map(edge => {
      const isAssigned = edge.groupKey === 'currentPermissionTeam';
      numAssignedTeams += isAssigned ? 1 : 0;
      return {
        ...edge.node,
        isAssigned
      };
    });
    return {
      teamItems,
      numAssignedTeams
    };
  }, [currentData]);
  const totalSize = data?.workspace2?.teamSelectOptions?.pageInfo?.totalSize ?? null;
  return {
    isLoading: !error && !data,
    items: teamItems,
    totalSize,
    numAssignedTeams,
    numJoinableTeams: (totalSize ?? 0) - numAssignedTeams
  };
};