import { Icon } from '@clubhouse/shapes-ds';
import BurndownChartSample from '@clubhouse/assets/svg/Burndown.svg?raw';
import CFDChartSample from '@clubhouse/assets/svg/CFD.svg?raw';
import CycleTimeChartSample from '@clubhouse/assets/svg/CycleTime.svg?raw';
import { Chip, IconChip } from '@clubhouse/shared/components/Chip';
import SVG from 'components/shared/SVG';
import { ChartUnstartedContainer, Content, Heading, IconWrapper, ImageSection, SampleChip } from './ChartUnstartedUtils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const SAMPLE_CHART_IMAGE = {
  burndown: BurndownChartSample,
  'cumulative-flow': CFDChartSample,
  'cycle-time': CycleTimeChartSample
};
const CHART_CONTENT_CENTER = {
  burndown: {
    icon: 'Check',
    heading: 'Are we on track?',
    content: 'View remaining work against an ideal burndown pace to gauge progress.'
  },
  'cumulative-flow': {
    icon: 'Workflow',
    heading: 'Is our workflow consistent?',
    content: 'Quickly identify bottlenecks in work.'
  },
  'cycle-time': {
    icon: 'RecentlyViewed',
    heading: 'What is our average story time?',
    content: 'Get an at-a-glance understanding of how long stories take to complete.'
  }
};
const CHART_CONTENT_END = {
  burndown: {
    icon: 'Estimate',
    heading: 'Did our scope change?',
    content: 'View work added, removed and completed each day.'
  },
  'cumulative-flow': {
    icon: 'Filter',
    heading: 'What is the status of work?',
    content: 'View work in progress over cycle time.'
  },
  'cycle-time': {
    icon: 'Bug',
    heading: 'How long do bug fixes take?',
    content: 'View time-to-complete by story type.'
  }
};
export const ChartUnstarted = ({
  chartType
}) => {
  const chartContentCenter = CHART_CONTENT_CENTER[chartType];
  const chartContentEnd = CHART_CONTENT_END[chartType];
  return _jsxs(ChartUnstartedContainer, {
    children: [_jsxs(ImageSection, {
      children: [_jsx(SVG, {
        rawSVG: SAMPLE_CHART_IMAGE[chartType]
      }), _jsx(SampleChip, {
        children: _jsx(Chip, {
          children: "Sample Chart"
        })
      })]
    }), _jsxs("div", {
      children: [_jsx(IconWrapper, {
        children: _jsx(IconChip, {
          kind: "blue",
          children: _jsx(Icon, {
            fill: "currentColor",
            icon: chartContentCenter.icon
          })
        })
      }), _jsx(Heading, {
        children: chartContentCenter.heading
      }), _jsx(Content, {
        children: chartContentCenter.content
      })]
    }), _jsxs("div", {
      children: [_jsx(IconWrapper, {
        children: _jsx(IconChip, {
          kind: "blue",
          children: _jsx(Icon, {
            fill: "currentColor",
            icon: chartContentEnd.icon
          })
        })
      }), _jsx(Heading, {
        children: chartContentEnd.heading
      }), _jsx(Content, {
        children: chartContentEnd.content
      })]
    })]
  });
};
ChartUnstarted.displayName = "ChartUnstarted";