import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.map.js";
import { useMemo, useState } from 'react';
import { ComboboxSelect } from '@clubhouse/shared/components/ComboboxSelect';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { getById, getTeamFromColumn } from 'data/entity/column';
import { getAll, getWorkflowStatesForTeam, useTeams } from 'data/entity/team';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function WorkflowSelect({
  selectedId,
  onChange
}) {
  const {
    teams
  } = useTeams();
  const column = getById(selectedId) ?? null;
  const team = getTeamFromColumn(column);
  const [query, setQuery] = useState('');
  const items = useMemo(() => {
    let isFirst = true;
    return teams.flatMap(team => {
      const items = getWorkflowStatesForTeam(team).filter(state => !query.trim() || team.name.toLowerCase().includes(query.toLowerCase()) || state.name.toLowerCase().includes(query.toLowerCase())).map(state => _jsx(ComboboxSelect.Option, {
        value: state.id,
        isSelected: state.id === selectedId,
        children: _jsx(Emojify, {
          children: state.name
        })
      }, state.id));
      if (items.length > 0) {
        items.unshift(_jsx(ComboboxSelect.LabelItem, {
          children: team.name
        }, `${team.id}-name`));
        if (!isFirst) items.unshift(_jsx(ComboboxSelect.Divider, {}, `${team.id}-divider`));
        isFirst = false;
      }
      return items;
    });
  }, [query, selectedId, teams]);
  return _jsxs(ComboboxSelect, {
    onChange: onChange,
    children: [_jsx(ComboboxSelect.Trigger, {
      children: _jsxs(ComboboxSelect.Target, {
        type: "button",
        IconRight: () => _jsx(SizedIcon, {
          icon: "ChevronDown"
        }),
        children: [team && getAll().length > 1 ? _jsxs("span", {
          style: {
            marginRight: 4
          },
          children: [team.name, ":"]
        }) : '', ' ', _jsx("span", {
          style: {
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          },
          children: _jsx(Emojify, {
            children: column.name || 'Select Target State'
          })
        })]
      })
    }), _jsx(ComboboxSelect.Menu, {
      children: _jsxs(ComboboxSelect.List, {
        children: [_jsx(ComboboxSelect.Input, {
          onSearch: setQuery
        }), items]
      })
    })]
  });
}
WorkflowSelect.displayName = "WorkflowSelect";