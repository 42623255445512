import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import ProfileModel from 'app/client/core/js/models/profile';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Profile'], ProfileModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Icon } from '@clubhouse/shapes-ds';
import { Avatar } from '@clubhouse/shared/components/Avatar';
import { Chip } from '@clubhouse/shared/components/Chip';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { useToggleState } from '@clubhouse/shared/hooks';
import { capitalize } from '@clubhouse/shared/utils';
import { RoleDropdown } from 'components/shared/RoleDropdown';
import { getLoggedInUserPermission } from 'data/entity/user';
import { adminOnly, ownerOnly } from 'utils/is';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
function ActiveUser({
  user,
  scimEnabled,
  onUpdateRole
}) {
  const me = getLoggedInUserPermission();
  const [isSaving, toggle] = useToggleState();
  return _jsxs("div", {
    className: "profile-container",
    "data-model": "Profile",
    "data-id": user.id,
    children: [_jsx(Avatar, {
      size: Avatar.SIZE.M,
      profile: user
    }), _jsxs("div", {
      className: "info-container",
      children: [_jsxs("div", {
        className: "name",
        children: [user.name, ' ', ownerOnly(user) && _jsx(Chip, {
          kind: "blue",
          children: _jsx("span", {
            className: "owner-indicator-title",
            children: "Owner"
          })
        })]
      }), _jsxs("div", {
        className: "username",
        children: ["@", user.mention_name]
      }), _jsx("div", {
        className: "email",
        children: _jsx("a", {
          href: `mailto:${user.email_address}`,
          children: user.email_address
        })
      })]
    }), _jsx("div", {
      className: "two-factor-auth-container",
      children: _jsx("span", {
        className: `fa fa-key two-factor-auth-${user.two_factor_auth_activated ? 'enabled' : 'disabled'}`,
        "data-tooltip": `Two-factor authentication ${user.two_factor_auth_activated ? '' : 'not '}enabled.`
      })
    }), _jsx("div", {
      className: "role-dropdown-container",
      children: !scimEnabled && user.id !== me.id && (ownerOnly(me) || adminOnly(me) && !ownerOnly(user)) ? _jsx(RoleDropdown, {
        value: user.role,
        isDisabled: isSaving,
        onItemSelected: async newRole => {
          toggle.on();
          try {
            await onUpdateRole(user.id, newRole);
          } finally {
            toggle.off();
          }
        }
      }) : scimEnabled ? _jsx("div", {
        className: "role-dropdown disabled",
        "data-tooltip": "Roles are managed through your identity provider.",
        children: capitalize(user.role)
      }) : user.id === me.id ? _jsx("div", {
        className: "role-dropdown disabled",
        "data-tooltip": "You cannot change your own role. Ask another admin or owner to change your role for you.",
        children: capitalize(user.role)
      }) : ownerOnly(user) ? _jsx("div", {
        className: "role-dropdown disabled",
        "data-tooltip": "Only owners can change the role of other owners.",
        children: capitalize(user.role)
      }) : _jsx("div", {
        className: "role-dropdown disabled",
        "data-tooltip": "Only admins and owners can change roles.",
        children: capitalize(user.role)
      })
    }), !scimEnabled && (ownerOnly(me) || adminOnly(me) && !ownerOnly(user)) && _jsx("div", {
      className: "actions",
      children: user.id !== me.id && _jsx("span", {
        className: "align-center",
        children: _jsx(UnstyledButton, {
          className: "disable-user",
          "data-on-click": "disableUser",
          "data-tooltip": `Disable ${user.name}`,
          children: _jsx(DeprecatedIconAdapter, {
            children: _jsx(Icon, {
              icon: "Clear"
            })
          })
        })
      })
    })]
  });
}
ActiveUser.displayName = "ActiveUser";
export function ActiveUsers({
  users,
  scimEnabled,
  onUpdateRole
}) {
  return _jsx(_Fragment, {
    children: users.map(user => _jsx(ActiveUser, {
      user: user,
      scimEnabled: scimEnabled,
      onUpdateRole: onUpdateRole
    }, user.id))
  });
}