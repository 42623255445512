import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { ComboboxSelect } from '@clubhouse/shared/components/ComboboxSelect';
import { useEntityStateFilter } from './utils';
import { getById as getWorkflowStateById } from 'data/entity/column';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { useWorkflowStates } from 'data/entity/workflow';
import { getById } from 'data/entity/team';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const List = ({
  workflowTeamId,
  activeId
}) => {
  const team = getById(workflowTeamId);
  const states = useWorkflowStates(team?.workflow?.id);
  return _jsxs(ComboboxSelect.List, {
    children: [_jsx(ComboboxSelect.Option, {
      value: "",
      isSelected: activeId === '',
      children: "All"
    }), _jsx(ComboboxSelect.Divider, {}), states.map(state => _jsx(ComboboxSelect.Option, {
      value: String(state.id),
      isSelected: String(state.id) === activeId,
      children: _jsx(Emojify, {
        children: state.name
      })
    }, state.id))]
  });
};
List.displayName = "List";
export function WorkflowStateSelector() {
  let label = 'All';
  const [entityStateFilter, setEntityStateFilter] = useEntityStateFilter();
  if (entityStateFilter && entityStateFilter.workflowStateId) {
    const state = getWorkflowStateById(entityStateFilter.workflowStateId);
    if (state) label = state.name;
  }
  return _jsxs(ComboboxSelect, {
    onChange: stateId => {
      setEntityStateFilter({
        workflowStateId: stateId ? Number(stateId) : null
      });
    },
    children: [_jsx(ComboboxSelect.Trigger, {
      children: _jsxs("button", {
        className: "action mini light-gray",
        disabled: !entityStateFilter?.workflowTeamId,
        children: [_jsx(Emojify, {
          children: label
        }), " ", _jsx("span", {
          className: "fa fa-caret-down"
        })]
      })
    }), _jsx(ComboboxSelect.Menu, {
      children: entityStateFilter?.workflowTeamId && _jsx(List, {
        workflowTeamId: entityStateFilter.workflowTeamId,
        activeId: String(entityStateFilter?.workflowStateId || '')
      })
    })]
  });
}
WorkflowStateSelector.displayName = "WorkflowStateSelector";