import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { usePendingInvites } from 'data/entity/invite';
import { maybeName as sortByMaybeName } from 'utils/sort';
import { Invite } from './Invite';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function Invites() {
  const pendingInvites = usePendingInvites();
  return _jsx(_Fragment, {
    children: pendingInvites.sort(sortByMaybeName).map(invite => _jsx(Invite, {
      id: invite.id
    }, invite.id))
  });
}