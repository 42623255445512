import Logo from '@clubhouse/assets/png/third-party-logos/savio_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Savio() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.SAVIO
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Savio"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Savio Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://www.savio.io/integrations/shortcut/",
          children: "Savio"
        }), " helps teams centralize, commercialize and prioritize product feedback. Automatically sync Feature Request statuses in Savio with the progress of linked stories in ", BRAND.NAME, "."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://www.shortcut.com/integrations/savio",
          children: "Learn more"
        }), " on how the Savio integration works in ", BRAND.NAME, "."]
      })]
    })]
  });
}
Savio.displayName = "Savio";