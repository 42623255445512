import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useMemo } from 'react';
import { StatelessTable } from '@clubhouse/shared/components/Table/StatelessTable';
import { ScrollContainerGroup } from '@clubhouse/shared/components/Table/internalComponents/ScrollContainer';
import { EpicRow, EpicRowForInfiniteScroll } from 'components/gql/epics/table/EpicsTable';
import { LOADING_STATE } from 'components/gql/utils/Loading';
import { StaleLoading } from 'components/shared/StaleLoading';
import { useEpicReordering } from '../../hooks/useEpicReordering';
import { createGroups } from './groupBy';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const GroupedTable = ({
  group,
  offset,
  isLoading,
  onReorder,
  infiniteScroll,
  ...props
}) => {
  return _jsx(StatelessTable, {
    indexOffset: offset,
    HeaderComponent: group.header,
    isCollapsible: true,
    initiallyCollapsed: false,
    allItems: group.items,
    items: group.items,
    onReorder: onReorder,
    RowComponent: infiniteScroll ? EpicRowForInfiniteScroll : EpicRow,
    ...props,
    ...(isLoading && LOADING_STATE)
  });
};
GroupedTable.displayName = "GroupedTable";
export const GroupedTables = ({
  groupBy,
  items,
  columns,
  sortColumn,
  sortDirection,
  onSort,
  isLoading,
  isStale,
  footer,
  updateQuery,
  infiniteScroll
}) => {
  const onReorder = useEpicReordering({
    epics: items,
    sortDirection,
    updateQuery
  });
  const groups = useMemo(() => createGroups(groupBy, items), [items, groupBy]);
  let offset = 0;
  return _jsxs(ScrollContainerGroup, {
    children: [groups.map(group => {
      const currOffset = offset;
      offset += group.items.length;
      return _jsx(GroupedTable, {
        infiniteScroll: infiniteScroll,
        group: group,
        offset: currOffset,
        columns: columns,
        onReorder: onReorder,
        isLoading: isLoading,
        onSort: onSort,
        sortDirection: sortDirection,
        sortColumn: sortColumn
      }, group.key || 'none');
    }), footer, isStale && _jsx(StaleLoading, {})]
  });
};
GroupedTables.displayName = "GroupedTables";