import "core-js/modules/es.array.push.js";
import { PAGE_NAMES, generatePathWithSlug, getPathForPage, navigateTo } from 'utils/navigation';
import { getHistory } from './history';

/**
 * Navigates to a Doc.
 *
 * @param docUrlId - The Doc's URL ID. It has to be the ID as seen in the URL, not the Data Layer ID!
 *
 * Navigating to a document is a bit tricky because we use multiple histories,
 * meaning that in order to navigate to a document, we need to leverage `navigateTo`,
 * but also to sync Docs' history so it knows what to render. We used to do a full reload
 * of the page to bypass this issue, but it's becoming quite tedious now that we have Docs
 * as a core feature (e.g., search & recently viewed).
 *
 * @example
 * ```ts
 * navigateToDoc('IkRvYyI6I3V1aWQgIjY0OWM3NDgxLTdkMzgtNDMzOC05ZGMxLTZjNGE2MGVhMWE4MyI=');
 * ```
 *
 */
export function navigateToDoc(docUrlId) {
  const url = generatePathWithSlug(getPathForPage(PAGE_NAMES.DOC), {
    docId: docUrlId
  });
  navigateTo({
    url: url
  });
  getHistory()?.push(`/${docUrlId}`);
}