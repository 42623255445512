import { GroupStorySpaceFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { GetGroupStoryListDocument } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { SegmentsFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { GroupHeaderAggregationsFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { StoryInGroupIdsFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useLazyQuery, useQuery } from 'gql';
import { useDataLayerUpdateEvent } from 'gql/components/updates/useDataLayerUpdateEvent';
import { getSpaceData } from '../space/utils';
const StoryInGroupIdsFragment = StoryInGroupIdsFragmentDoc;
const GroupHeaderAggregationsFragment = GroupHeaderAggregationsFragmentDoc;
const SegmentsFragment = SegmentsFragmentDoc;
export const GetGroupStoryListQuery = GetGroupStoryListDocument;
GroupStorySpaceFragmentDoc;
const mapToStoriesInGroup = d => getSpaceData(d)?.config?.groupResults?.groupResults || [];
const mapToSegmentsInGroup = d => getSpaceData(d)?.config?.groupResults?.segments || [];
const mapToHeaderAggregations = d => getSpaceData(d)?.config?.groupResults?.headerAggregations || [];
const mapToHeaders = d => getSpaceData(d)?.config?.groupResults?.headers || [];
export const useLazyStoriesInGroupQuery = () => {
  const [fetchStories] = useLazyQuery(GetGroupStoryListQuery);
  return useCallback(async variables => {
    let retryCount = 0;
    let {
      data
    } = await fetchStories(variables);

    // The response sometimes goes through with warnings
    // When this happens the result list comes back as empty and that breaks the UI.
    // To workaround this we're triggering a refetch if the response includes extension warnings.
    while (retryCount < 3 && data && data.extensions) {
      retryCount += 1;
      const refetchResult = await fetchStories(variables);
      data = refetchResult.data;
    }
    return {
      stories: mapToStoriesInGroup(data),
      segments: mapToSegmentsInGroup(data),
      headers: mapToHeaders(data),
      headerAggregations: mapToHeaderAggregations(data),
      totalSize: getSpaceData(data)?.config?.groupResults?.pageInfo?.totalSize ?? null
    };
  }, [fetchStories]);
};
const UPDATE_EVENT_TYPES = ['Story'];
export const useStoriesInGroupQuery = ({
  variables,
  skip
}) => {
  const {
    data,
    previousData,
    refetch
  } = useQuery(GetGroupStoryListQuery, {
    variables,
    skip
  });
  useDataLayerUpdateEvent(() => refetch(), {
    filter: UPDATE_EVENT_TYPES
  });
  return {
    stories: mapToStoriesInGroup(data || previousData),
    segments: mapToSegmentsInGroup(data || previousData),
    headers: mapToHeaders(data || previousData),
    headerAggregations: mapToHeaderAggregations(data || previousData),
    totalSize: getSpaceData(data || previousData)?.config?.groupResults?.pageInfo?.totalSize ?? null
  };
};