import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useIterationsEnabledForCurrentOrg } from 'data/entity/organization';
import { PageNotFoundContent } from 'pages/404/404';
import { TeamScopedPageTitle } from 'components/team-navigation';
import { useTeamScopedIterations } from './useTeamScopedIterations';
import { NoIterations } from './NoIterations';
import { Filters } from './Filters';
import { NoMatchingIterations } from './NoMatchingIterations';
import { useFilteredIterations } from './useFilteredIterations';
import { InViewProvider } from '@clubhouse/shared/components/InViewProvider';
import { IterationColumn } from './IterationColumn';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDragToScroll } from '@clubhouse/shared/hooks/useDragToScroll';
import { ShimmerColumn } from './ShimmerColumn';
import { useAppState } from 'utils/appState';
import { useLatestRef } from '@clubhouse/shared/hooks';
import debounce from 'lodash/debounce';
import { StoriesPanel } from './StoriesPanel/StoriesPanel';
import { autoScrollForElements } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/element';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const NS = 'iterationsPage';
const SCROLL_POSITION_KEY = `${NS}.scrollPositionV2`;
const PAGE_SIZE = 50;
const Content = () => {
  const allIterations = useTeamScopedIterations();
  const {
    filteredIterations,
    clearAllFilters
  } = useFilteredIterations(allIterations);
  const [scrollState, setScrollState] = useAppState({
    appStateKey: SCROLL_POSITION_KEY,
    default: {
      left: 0,
      visibleColumns: PAGE_SIZE
    }
  });
  const [visibleColumns, setVisibleColumns] = useState(scrollState.visibleColumns);
  const scrollContainerRef = useDragToScroll('horizontal');
  const visibleColumnsRef = useLatestRef(visibleColumns);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only on page load
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = scrollState.left;
    }
  }, []);
  useEffect(() => {
    const el = scrollContainerRef.current;
    if (!el) return;
    const onScroll = debounce(() => {
      setScrollState({
        left: el.scrollLeft,
        visibleColumns: visibleColumnsRef.current
      });
    }, 100);
    el.addEventListener('scroll', onScroll, {
      passive: true
    });
    const cleanup = autoScrollForElements({
      element: el,
      canScroll() {
        return true;
      }
    });
    return () => {
      cleanup();
      el.removeEventListener('scroll', onScroll);
    };
  }, [setScrollState, scrollContainerRef, visibleColumnsRef]);
  const onVisible = useCallback(index => {
    if (index >= visibleColumns - 10) setVisibleColumns(visibleColumns + PAGE_SIZE);
  }, [visibleColumns]);
  if (!allIterations?.length) return _jsx(NoIterations, {});
  return _jsx(InViewProvider, {
    children: _jsxs("div", {
      className: "iterations-page-wrapper",
      children: [_jsx("div", {
        className: "header",
        children: _jsx(TeamScopedPageTitle, {
          title: "Iterations"
        })
      }), _jsxs("div", {
        className: "content-container",
        children: [_jsxs("div", {
          className: "filters-and-iterations",
          children: [_jsx(Filters, {
            onFilterChange: () => {
              setVisibleColumns(PAGE_SIZE);
              setScrollState({
                left: 0,
                visibleColumns: PAGE_SIZE
              });
              if (scrollContainerRef.current) scrollContainerRef.current.scrollLeft = 0;
            }
          }), _jsx("div", {
            id: "iterations-columns",
            className: "iterations-container",
            style: {
              display: !filteredIterations?.length ? 'none' : undefined
            },
            children: _jsx("div", {
              className: "iterations-column-view clearfix",
              children: _jsx("div", {
                ref: scrollContainerRef,
                className: "iterations-columns-outer",
                children: _jsxs("div", {
                  className: "iterations-columns-inner",
                  children: [filteredIterations.slice(0, visibleColumns).map((iteration, index) => _jsx(Fragment, {
                    children: _jsx(IterationColumn, {
                      id: iteration.id,
                      index: index,
                      onVisible: onVisible
                    })
                  }, iteration.id)), filteredIterations.length > visibleColumns && _jsx(ShimmerColumn, {})]
                })
              })
            })
          }), !filteredIterations?.length && _jsx(NoMatchingIterations, {
            onClearFilters: clearAllFilters
          })]
        }), _jsx("div", {
          id: "storiesPanel",
          children: _jsx(StoriesPanel, {})
        })]
      })]
    })
  });
};
Content.displayName = "Content";
export function IterationsPage() {
  const iterationsEnabled = useIterationsEnabledForCurrentOrg();
  if (!iterationsEnabled) return _jsx(PageNotFoundContent, {});
  return _jsx(Content, {});
}
IterationsPage.displayName = "IterationsPage";