import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { useCallback, useRef } from 'react';
export function useCallAll(fnName, objects) {
  const fns = useRef([]);
  fns.current = objects.map(obj => obj[fnName]);
  return useCallback(() => {
    fns.current.forEach(fn => fn());
  }, []);
}