import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import { WorkflowSelectIteration_ReportConfig_WorkflowSelectFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { ReportSelectFilter } from '../reports-v2/ReportSelectFilter';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WorkflowSelectIteration_ReportConfig_WorkflowSelectFragment = WorkflowSelectIteration_ReportConfig_WorkflowSelectFragmentDoc;
export const WorkflowSelect = ({
  options,
  isLoading,
  onChange,
  selectedValue
}) => {
  // We can't use `isSelected` here because options are coming from the page config which isn't executed on changes
  const displayable = useMemo(() => options.find(option => option.__typename === 'SelectItemEdgeOption' && option.value === selectedValue)?.displayable, [options, selectedValue]);

  // Adapter to turn `Iteration_ReportConfig_WorkflowSelect` into `Report_Selector`
  const selector = useMemo(() => ({
    __typename: 'Report_Selector',
    value: selectedValue,
    valueDisplayable: {
      __typename: 'Workflow',
      id: displayable && 'id' in displayable ? displayable.id : '',
      displayableName: displayable?.displayableName ?? ''
    },
    valueOptions: {
      __typename: 'SelectItemsConnection',
      edges: options.map(option => ({
        ...option,
        // We need to rewrite `isSelected` here because options are coming from the page config which isn't executed on changes
        isSelected: option.__typename === 'SelectItemEdgeOption' && option.value === selectedValue
      }))
    }
  }), [options, displayable, selectedValue]);
  return _jsx(ReportSelectFilter, {
    label: "Workflow",
    selector: selector,
    isLoading: isLoading,
    onChange: onChange
  });
};
WorkflowSelect.displayName = "WorkflowSelect";