import Logo from '@clubhouse/assets/png/third-party-logos/guru_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Guru() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.GURU
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Guru"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Guru Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Use ", _jsx(ExternalLink, {
          href: "https://www.getguru.com/integrations/shortcut",
          children: "Guru"
        }), " to store, enable AI-powered searches within ", BRAND.NAME, ", helping you quickly discover relevant information across Stories, Epics, and Roadmaps."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://www.shortcut.com/integrations/guru",
          children: "Learn more"
        }), " on how the Guru integration works in ", BRAND.NAME, "."]
      })]
    })]
  });
}
Guru.displayName = "Guru";