import { Icon } from '@clubhouse/shapes-ds';
import { Avatar, AvatarWithoutProfile } from '@clubhouse/shared/components/Avatar';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, useToast } from '@clubhouse/shared/components/Toast';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { acceptInvite, usePendingInvite } from 'data/entity/invite';
import { PAGE_NAMES, redirect } from 'utils/navigation';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Invite({
  id
}) {
  const invite = usePendingInvite(id);
  const {
    addToast
  } = useToast();
  if (!invite) return null;
  const {
    name,
    url_slug
  } = invite.organization;
  const onAcceptInvite = async () => {
    try {
      const {
        organization
      } = await acceptInvite(id);
      redirect(`/${organization.url_slug}/${PAGE_NAMES.STORIES}`);
    } catch {
      addToast({
        kind: 'warning',
        timeout: 5000,
        Content: () => _jsx(ToastText, {
          children: "Unable to join workspace."
        })
      });
    }
  };
  return _jsxs("div", {
    className: "org pending",
    children: [_jsxs("div", {
      className: "title permission",
      children: [_jsx("h3", {
        children: name
      }), _jsx("span", {
        className: "tagged green",
        children: _jsxs("span", {
          className: "align-center",
          children: [_jsx(DeprecatedIconAdapter, {
            width: 12,
            fill: "currentColor",
            children: _jsx(Icon, {
              icon: "Mail"
            })
          }), ' ', "Invited"]
        })
      }), _jsx("button", {
        className: "action micro green",
        onClick: onAcceptInvite,
        children: "Join!"
      })]
    }), _jsx("div", {
      className: "details",
      children: _jsxs("ul", {
        className: "info",
        children: [_jsx("li", {
          children: _jsxs(Spaced, {
            horizontally: true,
            amount: "space1",
            align: "center",
            children: [_jsx(AvatarWithoutProfile, {
              id: invite.created_by.id,
              size: Avatar.SIZE.S,
              fullName: invite.created_by.name,
              imageUrl: invite.created_by.display_icon?.url
            }), _jsxs(Text, {
              size: Text.SIZE.SMALL,
              children: [_jsx("strong", {
                children: invite.created_by.name
              }), " invited you to join this Workspace!"]
            })]
          })
        }), _jsxs("li", {
          children: [_jsx("strong", {
            children: "URL:"
          }), ' ', _jsxs("a", {
            href: `/${url_slug}/`,
            className: "url-slug",
            children: ["https://", BRAND.DOMAIN_APP_WEBSITE, "/", url_slug]
          })]
        }), _jsxs("li", {
          children: [_jsx("strong", {
            children: "Email:"
          }), " ", invite.email]
        })]
      })
    })]
  });
}
Invite.displayName = "Invite";