import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { useCallback, useMemo, useState } from 'react';
import { LoadingDots } from '@clubhouse/shared/animations';
import { Button } from '@clubhouse/shared/components/Button';
import { ListRangeObserver } from '@clubhouse/shared/components/ListRangeObserver';
import { DEFAULT_ROW_HEIGHT } from '@clubhouse/shared/components/Table/config';
import { getKeyEncoder } from '@clubhouse/shared/utils/keys';
import { BulkSelectProvider } from 'components/gql/bulkSelect/BulkSelectProvider';
import { useEnabledCustomFields } from 'components/gql/customFields/useEnabledCustomFields';
import { FilterStateProvider } from 'components/gql/filters/FilterStateProvider';
import { LoadMoreButton } from 'components/gql/pagination/LoadMoreButton';
import { TableFooter } from 'components/gql/pagination/TableFooter';
import { TableResults } from 'components/gql/pagination/TableResults';
import { ListPageLayout, PaddingScroll } from 'components/layout/ListPageLayout';
import { StaleLoading } from 'components/shared/StaleLoading';
import { TableColumnSelectFilterField } from 'components/shared/TableColumnSelectFilterField';
import { TableStateProviderWithAppState } from 'components/shared/TableStateProviderWithAppState';
import { BulkEditTableActions } from 'components/shared/bulk-actions/BulkEditTableActions';
import { TeamScopedPageTitle } from 'components/team-navigation';
import { backlogStoryDefaults } from 'gql/variables';
import { BacklogFilters, BacklogFiltersSearch } from 'pages/backlog/entities/stories/components/BacklogFilters';
import { GroupedTables } from 'pages/backlog/entities/stories/components/table/GroupedTables';
import { renderAddNewStory } from 'utils/addNew';
import { EVENT_TYPES, useEventListener } from 'utils/collectionizeToApolloMessageBus';
import { useGqlActiveTransaction } from 'utils/profiler/sentry';
import { BacklogEmptyState } from '../BacklogEmptyState';
import { NoBacklogWorkflowStateEmptyState } from '../NoBacklogWorkflowStateEmptyState';
import { NoStoriesInBacklogEmptyState } from '../NoStoriesInBacklogEmptyState';
import { BacklogGroupBySelect } from '../StoriesGroupBySelect';
import { BacklogErrorState } from './BacklogErrorState';
import { BacklogTable } from './BacklogTable';
import { SORT_COLUMN_VALUES } from './groupBy';
import { useBacklogTableDataWithLoadMore } from './useBacklogTableData';
import { useBacklogTableSortUrlState } from './useBacklogTableSortUrlState';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const BacklogTableViewWithLoadMore = () => {
  const {
    sortColumn,
    sortDirection,
    onSortChange
  } = useBacklogTableSortUrlState({
    initialColumn: 'name',
    initialDirection: 'ascending',
    validValues: SORT_COLUMN_VALUES
  });
  const {
    isInitialDataFromCache,
    isLoading,
    hasNoBacklogStoriesInWorkspace,
    refetch,
    stories: rawStories,
    groupBy,
    isStale,
    pageInfo,
    columns,
    updateQuery,
    error,
    isFetchingMore,
    fetchNextPage,
    setPollingRange,
    ...results
  } = useBacklogTableDataWithLoadMore({
    sortColumn,
    sortDirection
  });
  useGqlActiveTransaction({
    isInitialDataFromCache,
    isLoading
  });
  useEventListener(EVENT_TYPES.STORY_CREATED, refetch);
  useEventListener(EVENT_TYPES.STORY_UPDATED, refetch);
  const backlogStoryDefaultValues = useMemo(() => {
    const defs = results?.defaults?.workflowStateId && results?.defaults?.workflowId ? {
      workflowStateId: results.defaults.workflowStateId,
      workflowId: results.defaults.workflowId,
      teamId: results.defaults.teamId
    } : null;
    backlogStoryDefaults(defs);
    return defs;
  }, [results?.defaults?.teamId, results?.defaults?.workflowId, results?.defaults?.workflowStateId]);
  const onAddStory = useCallback(() => {
    if (backlogStoryDefaultValues) {
      renderAddNewStory({
        workflow_state_id: backlogStoryDefaultValues.workflowStateId,
        workflow_id: backlogStoryDefaultValues.workflowId,
        group_id: backlogStoryDefaultValues.teamId
      }, {
        useCreateAnother: false
      });
    }
  }, [backlogStoryDefaultValues]);
  const [root, setRoot] = useState(null);
  const stories = useMemo(() => {
    const getKey = getKeyEncoder();
    return rawStories.map(story => ({
      ...story,
      itemKey: getKey(story.id, story.publicId)
    }));
  }, [rawStories]);
  if (!error && !backlogStoryDefaultValues && !isLoading) return _jsx(NoBacklogWorkflowStateEmptyState, {});
  if (!error && hasNoBacklogStoriesInWorkspace) return _jsx(NoStoriesInBacklogEmptyState, {
    onAddStory: onAddStory
  });
  const hasEmptyState = !isLoading && !stories.length && !pageInfo?.totalSize;
  const hasMoreData = (pageInfo?.totalSize ?? 0) > stories.length;
  return _jsx(BulkSelectProvider, {
    items: stories.map(s => s.publicId),
    children: _jsx(ListRangeObserver, {
      root: root,
      rootMargin: `${DEFAULT_ROW_HEIGHT * 5}px 0px`,
      onChange: setPollingRange,
      children: _jsx(PaddingScroll, {
        ref: setRoot,
        restoreScroll: !isLoading && 'backlog-table',
        children: error && hasEmptyState ? _jsx(BacklogErrorState, {
          error: error
        }) : hasEmptyState ? _jsxs(_Fragment, {
          children: [_jsx(BacklogEmptyState, {}), isStale && _jsx(StaleLoading, {})]
        }) : groupBy ? _jsx(GroupedTables, {
          infiniteScroll: true,
          groupBy: groupBy,
          onAddStory: onAddStory,
          items: stories,
          columns: columns,
          sortColumn: sortColumn,
          sortDirection: sortDirection,
          onSort: onSortChange,
          isLoading: isLoading,
          isStale: isStale,
          updateQuery: updateQuery,
          footer: _jsx(TableFooter, {
            results: _jsx(TableResults, {
              offset: 0,
              pageSize: stories.length,
              totalItemCount: pageInfo?.totalSize
            }),
            pagination: hasMoreData ? _jsx("div", {
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              },
              children: _jsx(LoadMoreButton, {
                loading: isFetchingMore,
                onClick: fetchNextPage
              })
            }) : null
          })
        }) : _jsx(BacklogTable, {
          ...results,
          infiniteScroll: true,
          onAddStory: onAddStory,
          columns: columns,
          isStale: isStale,
          stories: stories,
          isLoading: isLoading,
          sortColumn: sortColumn,
          sortDirection: sortDirection,
          onSort: onSortChange,
          footer: _jsx(TableFooter, {
            results: _jsx(TableResults, {
              offset: 0,
              pageSize: stories.length,
              totalItemCount: pageInfo?.totalSize
            }),
            pagination: hasMoreData ? _jsx("div", {
              style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              },
              children: _jsx(Button, {
                kind: Button.KIND.SECONDARY,
                IconLeft: isFetchingMore ? LoadingDots : null,
                onClick: fetchNextPage,
                isDisabled: isFetchingMore,
                children: "Load more results"
              })
            }) : null
          })
        })
      })
    })
  });
};
BacklogTableViewWithLoadMore.displayName = "BacklogTableViewWithLoadMore";
const DEFAULT_DISABLED_COLUMNS = ['requester'];
export const BacklogTableView = () => {
  const enabledCustomFields = useEnabledCustomFields();
  return _jsx(TableStateProviderWithAppState, {
    appStateKeyPrefix: "backlog",
    defaultDisabledColumns: DEFAULT_DISABLED_COLUMNS,
    children: _jsx(FilterStateProvider, {
      children: _jsx(ListPageLayout, {
        title: _jsx(TeamScopedPageTitle, {
          title: "Backlog"
        }),
        filters: _jsx(BacklogFilters, {
          enabledCustomFields: enabledCustomFields
        }),
        query: _jsx(BacklogFiltersSearch, {}),
        viewSettings: _jsxs(_Fragment, {
          children: [_jsx(BacklogGroupBySelect, {
            enabledCustomFields: enabledCustomFields
          }), _jsx(TableColumnSelectFilterField, {})]
        }),
        bulkEditActions: _jsx(BulkEditTableActions, {}),
        content: _jsx(BacklogTableViewWithLoadMore, {})
      })
    })
  });
};
BacklogTableView.displayName = "BacklogTableView";