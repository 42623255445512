import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import groupBy from 'lodash/groupBy';
import { Fragment, useMemo } from 'react';
import CheckDocumentsPng from '@clubhouse/assets/png/empty-tasks.png';
import { StoryTypeIcon } from 'components/shared/StoryTypeIcon';
import { useAllColumnsInterleaved } from 'data/entity/column';
import { getById } from 'data/entity/story';
import { filterTasksInColumn, getActiveTasksForCurrentUser, getStartedStoryTasksForCurrentUser, useTasks } from 'data/entity/task';
import { useAppState } from 'utils/appState';
import { Task as TaskComponent } from 'components/shared/Task';
import { DisplaySelect } from './DisplaySelect';
import { StoryLinkName } from './components';
import { LoadingDots } from '@clubhouse/shared/animations';
import { Center } from '@clubhouse/shared/components/Center';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const DISPLAY_ITEMS = [{
  name: 'In Progress',
  note: 'My tasks from in progress stories.',
  value: 'STARTED_ONLY'
}, {
  name: 'To-Do and In Progress',
  note: 'My tasks from to-do and in progress stories.',
  value: 'UNFINISHED_ONLY'
}];
const defaultDisplayItem = DISPLAY_ITEMS[1];
export const ActiveTasks = Object.assign(() => {
  const [taskFilter, setTaskFilter] = useAppState({
    appStateKey: 'dashboardTaskFilter',
    default: defaultDisplayItem.value
  });
  const normalizedTaskFilter = useMemo(() => {
    const displayItem = DISPLAY_ITEMS.find(({
      value
    }) => value === taskFilter);
    return displayItem?.value || defaultDisplayItem.value;
  }, [taskFilter]);
  const allColumns = useAllColumnsInterleaved();
  const allTasks = useTasks();
  const {
    columns,
    tasks
  } = useMemo(() => {
    if (normalizedTaskFilter === 'STARTED_ONLY') {
      return {
        columns: allColumns.filter(c => c.type === 'started'),
        tasks: getStartedStoryTasksForCurrentUser(allTasks)
      };
    } else {
      return {
        columns: allColumns.filter(c => c.type !== 'done'),
        tasks: getActiveTasksForCurrentUser(allTasks)
      };
    }
  }, [normalizedTaskFilter, allColumns, allTasks]);
  const hasTasks = tasks.length > 0;
  const tasksPerColumn = columns.map(column => [column, filterTasksInColumn(tasks, column)]).filter(([, tasks]) => tasks.length > 0);
  return _jsxs(_Fragment, {
    children: [_jsxs("div", {
      className: "dashboard-header",
      children: [_jsx("h3", {
        children: "My Tasks"
      }), _jsx("div", {
        className: "bucket-actions",
        children: _jsx(DisplaySelect, {
          label: "Select which Tasks to show",
          current: normalizedTaskFilter,
          options: DISPLAY_ITEMS,
          onChange: setTaskFilter
        })
      })]
    }), hasTasks ? _jsx("div", {
      className: "active-tasks",
      children: tasksPerColumn.map(([column, tasksByColumn]) => _jsx(Fragment, {
        children: Object.entries(groupBy(tasksByColumn, 'story_id')).map(([storyId, tasks]) => {
          const story = getById(storyId);
          if (!story) return null;
          return _jsxs(Fragment, {
            children: [_jsxs("div", {
              className: "story-above-task",
              children: [_jsx(StoryTypeIcon, {
                size: 18,
                storyType: story.story_type
              }), _jsx(StoryLinkName, {
                story: getById(storyId)
              })]
            }), tasks.map(task => _jsx(TaskComponent, {
              task: task
            }, task.id))]
          }, storyId);
        })
      }, column.id))
    }) : _jsxs("div", {
      className: "empty",
      children: [_jsx("img", {
        src: CheckDocumentsPng,
        className: "dashboard__image",
        alt: ""
      }), _jsx("p", {
        className: "heading",
        children: "You have no active tasks"
      }), _jsx("p", {
        className: "subheading",
        children: "Add tasks to any story and assign yourself."
      })]
    })]
  });
}, {
  Loading: () => _jsxs(_Fragment, {
    children: [_jsxs("div", {
      className: "dashboard-header",
      children: [_jsx("h3", {
        children: "My Tasks"
      }), _jsx("div", {
        className: "bucket-actions"
      })]
    }), _jsx("div", {
      className: "active-tasks",
      children: _jsx(Center, {
        children: _jsx(LoadingDots, {})
      })
    })]
  })
});