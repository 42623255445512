import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { ReportSelectFilterReport_SelectorFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import { ReportSelectFilterSelectItemEdgeUnionFragmentDoc } from "../../../../datalayer/__generated_graphql_types__/graphql";
import DOMPurify from 'dompurify';
import { gql } from '@clubhouse/datalayer';
import { ComboboxSelect } from '@clubhouse/shared/components/ComboboxSelect';
import { DropdownMenu } from '@clubhouse/shared/components/DropdownMenu';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { ROLE_DROPDOWN_Z_INDEX } from 'components/shared/RoleDropdown';
import { CheckboxItem, FilterLabel, SecondaryText, SelectContainer, ToggleButton } from 'components/reports/ReportSelectUtils';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ReportSelectFilterSelectItemEdgeUnionFragment = ReportSelectFilterSelectItemEdgeUnionFragmentDoc;
export const ReportSelectFilterReport_SelectorFragment = ReportSelectFilterReport_SelectorFragmentDoc;
export const TARGET_OPTION_VALUES = ['custom', 'ALL'];
function isTargetOptionValue(optionValue) {
  return TARGET_OPTION_VALUES.includes(optionValue);
}
export const ReportSelectFilter = ({
  label,
  selector,
  isLoading,
  LoadingComponent,
  descriptions,
  onChange
}) => {
  const selectedDisplayableName = (() => {
    if (isLoading) {
      // We only want to show the loading component if the displayable name is not available
      // as it could be available earlier from the cache, even though `isLoading` is true.
      if (LoadingComponent && !selector) {
        return LoadingComponent;
      }
    }
    return selector?.valueDisplayable.displayableName ?? '';
  })();
  return _jsxs(SelectContainer, {
    children: [_jsx(FilterLabel, {
      children: label
    }), _jsxs(DropdownMenu, {
      modal: false,
      children: [_jsx(DropdownMenu.Trigger, {
        asChild: true,
        children: _jsx(ToggleButton, {
          children: selectedDisplayableName
        })
      }), _jsx(DropdownMenu.Portal, {
        children: _jsx(DropdownMenu.Content, {
          align: "start",
          children: !isLoading && selector?.valueOptions.edges ? selector.valueOptions.edges.map(option => {
            if (option.__typename !== 'SelectItemEdgeOption') return null;
            const {
              value,
              displayable,
              isSelected,
              isDisabled
            } = option;
            const description = descriptions?.[value];
            const showDisabledOptionTooltip = isTargetOptionValue(value) && isDisabled;
            const tooltipContent = value === 'custom' ? 'Select a workflow other than “All” to use Custom states.' : 'Select a type other than “Custom” to view All workflows.';
            return showDisabledOptionTooltip ? _jsx(Tooltip, {
              content: tooltipContent
              /* Required for the tooltip to be visible on hover of 'disabled' DOM elements */,
              disabled: false
              /* Set z-index so that the select tooltip sits above the report dropdowns */,
              zIndex: ROLE_DROPDOWN_Z_INDEX + 1,
              children: _jsxs(CheckboxItem, {
                onSelect: () => onChange(value),
                checked: isSelected,
                disabled: Boolean(isDisabled),
                children: [displayable.displayableName, description ? _jsx(SecondaryText, {
                  dangerouslySetInnerHTML: {
                    __html: DOMPurify.sanitize(description)
                  }
                }) : null]
              })
            }, value) : _jsxs(CheckboxItem, {
              onSelect: () => onChange(value),
              checked: isSelected,
              disabled: Boolean(isDisabled),
              children: [displayable.displayableName, description ? _jsx(SecondaryText, {
                dangerouslySetInnerHTML: {
                  __html: DOMPurify.sanitize(description)
                }
              }) : null]
            }, value);
          }) : _jsx(ComboboxSelect.ListLoading, {})
        })
      })]
    })]
  });
};
ReportSelectFilter.displayName = "ReportSelectFilter";