import { Icon } from '@clubhouse/shapes-ds';
import { useCallback } from 'react';
import { Button } from '@clubhouse/shared/components/Button';
import { FixedActionRow } from '@clubhouse/shared/components/Table/components/FixedActionRow';
import { isLoggedInUserObserver } from 'data/entity/user';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const PlusIcon = () => _jsx(Icon, {
  icon: "Add",
  fill: "currentColor"
});
PlusIcon.displayName = "PlusIcon";
export function CreateBacklogStoryTableAction({
  onClick
}) {
  const isReady = useConsolidatedDataFetched();
  const handleClick = useCallback(() => {
    if (isReady) onClick();
  }, [isReady, onClick]);
  return _jsx(FixedActionRow, {
    isDisabled: !isReady,
    onClick: handleClick,
    children: _jsx(Button, {
      kind: Button.KIND.SECONDARY,
      size: Button.SIZE.MEDIUM,
      fit: Button.FIT.MEDIUM,
      IconRight: PlusIcon,
      isDisabled: !isReady || isLoggedInUserObserver(),
      children: "Create Backlog Story"
    })
  });
}
CreateBacklogStoryTableAction.displayName = "CreateBacklogStoryTableAction";