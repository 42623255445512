import Logo from '@clubhouse/assets/png/third-party-logos/plain_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function Plain() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.PLAIN
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Plain"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Plain Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["Use ", _jsx(ExternalLink, {
          href: "https://www.plain.com/docs/integrations/shortcut",
          children: "Plain"
        }), " to quickly create a new Shortcut Story or link to an existing one. Once the Story is completed in Shortcut, Plain makes it easy to follow-up with the customer and close the loop."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://www.shortcut.com/integrations/plain",
          children: "Learn more"
        }), " on how the Plain integration works in Shortcut."]
      })]
    })]
  });
}
Plain.displayName = "Plain";