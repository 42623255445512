import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import EventDefinitionModel from 'app/client/core/js/models/eventDefinition';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'EventDefinition'], EventDefinitionModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Chip } from '@clubhouse/shared/components/Chip';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, ToastTitle, addToast } from '@clubhouse/shared/components/Toast';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { deleteEventDefinition, updateWorkflowState, useDefinitionsForType } from 'data/entity/eventDefinition';
import { WorkflowSelect } from './WorkflowSelect';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function EventDefinitionsTable({
  type
}) {
  const definitions = useDefinitionsForType(type);
  if (!definitions?.length) return null;
  return _jsxs("table", {
    className: "table",
    cellPadding: 0,
    cellSpacing: 0,
    children: [_jsx("thead", {
      children: _jsxs("tr", {
        children: [_jsx("th", {
          children: "When this happens:"
        }), _jsx("th", {
          children: "Move linked story to:"
        }), _jsx("th", {
          children: "\xA0"
        })]
      })
    }), _jsx("tbody", {
      children: definitions.map(d => _jsxs("tr", {
        "data-model": "EventDefinition",
        "data-id": d.id,
        children: [_jsx("td", {
          children: _jsxs("div", {
            style: {
              width: 200
            },
            children: [_jsx(Chip, {
              kind: "blue",
              children: d.name
            }), !!d.match_name && _jsxs(_Fragment, {
              children: [_jsx("span", {
                style: {
                  fontSize: 14
                },
                children: d.match_type === 'branch' ? ' to ' : ' with '
              }), _jsx(Chip, {
                kind: "default",
                children: d.match_name
              })]
            })]
          })
        }), _jsx("td", {
          children: _jsx("div", {
            style: {
              width: 240
            },
            children: _jsx(WorkflowSelect, {
              selectedId: d.workflow_state_id,
              onChange: async id => {
                try {
                  await updateWorkflowState(d, id);
                  addToast({
                    kind: 'success',
                    timeout: 5_000,
                    Content: () => _jsx(ToastText, {
                      children: "Event handler updated."
                    })
                  });
                } catch (err) {
                  addToast({
                    kind: 'alert',
                    timeout: 10_000,
                    Content: () => _jsxs(Spaced, {
                      vertically: true,
                      amount: "space2",
                      children: [_jsx(ToastTitle, {
                        children: "Failed to update event handler"
                      }), _jsx(ToastText, {
                        children: err
                      })]
                    })
                  });
                }
              }
            })
          })
        }), _jsx("td", {
          children: _jsx(Tooltip, {
            content: "Delete Event",
            children: _jsx(UnstyledButton, {
              onClick: () => {
                if (window.confirm('Are you sure you want to delete this event handler?')) {
                  deleteEventDefinition(d).then(() => {
                    addToast({
                      kind: 'success',
                      timeout: 5000,
                      Content: () => _jsx(ToastText, {
                        children: "Event handler deleted."
                      })
                    });
                  }).catch(err => {
                    addToast({
                      kind: 'alert',
                      timeout: 10000,
                      Content: () => _jsx(ToastText, {
                        children: err
                      })
                    });
                  });
                }
              },
              children: _jsx(SizedIcon, {
                icon: "Trash",
                size: 14
              })
            })
          })
        })]
      }, d.id))
    })]
  });
}
EventDefinitionsTable.displayName = "EventDefinitionsTable";