import Logo from '@clubhouse/assets/png/third-party-logos/suretriggers_logo.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { isReadOnly } from 'data/entity/user';
import { ExternalLink } from './components/ExternalLink';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function SureTriggers() {
  const readOnly = isReadOnly();
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.SURETRIGGERS
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "SureTriggers"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "SureTriggers Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: [_jsx(ExternalLink, {
          href: "https://suretriggers.com/integrations/shortcut",
          children: "SureTriggers"
        }), " connects with your favorite apps to automate processes using triggers and actions. Set up triggers that respond to specific events in ", BRAND.NAME, ", and use actions to move your data exactly where it needs to go."]
      }), !readOnly && _jsxs(Layout.HelpMessage, {
        children: [_jsx(ExternalLink, {
          href: "https://www.shortcut.com/integrations/suretriggers",
          children: "Learn more"
        }), " on how the SureTriggers integration works in ", BRAND.NAME, "."]
      })]
    })]
  });
}
SureTriggers.displayName = "SureTriggers";