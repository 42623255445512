import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { QueryMiniKanbanStoriesDataDocument } from "../../../../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { useQuery } from 'gql';
import { useDataLayerUpdateEvent } from 'gql/components/updates/useDataLayerUpdateEvent';
const QUERY_MINI_KANBAN_STORIES_DATA = QueryMiniKanbanStoriesDataDocument;
export const useMiniKanbanStoriesQuery = (epicId, options) => {
  const {
    error,
    loading,
    data,
    previousData,
    refetch
  } = useQuery(QUERY_MINI_KANBAN_STORIES_DATA, {
    variables: {
      epicId
    },
    skip: options?.skip || false
  });
  useDataLayerUpdateEvent(() => refetch(), {
    filter: ['Story']
  });
  const epic = (data || previousData)?.node;
  const stories = (epic?.stories?.edges || []).map(({
    node
  }) => node);
  return {
    error,
    loading,
    stories,
    pageInfo: epic?.stories?.pageInfo || null,
    refetch
  };
};