import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import InviteModel from 'app/client/core/js/models/invite';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Invite'], InviteModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Icon } from '@clubhouse/shapes-ds';
import upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import { UnstyledButton } from '@clubhouse/shared/components/UnstyledButton';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export function InvitedUsers({
  invites,
  isReadOnly,
  showResendButton
}) {
  return _jsx("div", {
    className: isReadOnly ? 'read-only' : '',
    children: invites.map(invite => _jsxs("div", {
      className: "existing-invite",
      "data-model": "Invite",
      "data-id": invite.id,
      children: [_jsx("div", {
        className: "person-icon",
        children: _jsx(Icon, {
          icon: "User"
        })
      }), _jsx("a", {
        href: `mailto:${invite.email}`,
        className: "invite-email-link",
        children: invite.email
      }), _jsx("span", {
        className: "tagged blue",
        children: upperFirst(invite.role)
      }), _jsxs("span", {
        className: "invited-at",
        children: ["Invited ", moment(invite.created_at).fromNow()]
      }), _jsx("span", {
        className: "align-center",
        children: !isReadOnly && _jsxs(_Fragment, {
          children: [showResendButton && _jsx(UnstyledButton, {
            className: "resend-invite",
            "data-on-click": "resendInvite",
            "data-tooltip": "Re-send invite",
            children: _jsx(Icon, {
              icon: "Sync"
            })
          }), _jsx(UnstyledButton, {
            className: "delete-invite",
            "data-on-click": "deleteInvite",
            "data-tooltip": "Delete invite",
            children: _jsx(Icon, {
              icon: "Trash"
            })
          })]
        })
      })]
    }, invite.id))
  });
}
InvitedUsers.displayName = "InvitedUsers";