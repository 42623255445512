import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { QueryWorkspaceEpicStatesByStateDocument } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { EpicStateNodeFragmentDoc } from "../../../../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback, useMemo } from 'react';
import { gql } from '@clubhouse/datalayer';
import { emptyArray } from '@clubhouse/shared/utils/emptyArray';
import { useLazyQuery, useQuery } from 'gql';
import { useWorkspaceSlug } from 'utils/navigation';
export const EpicStateNodeFragment = EpicStateNodeFragmentDoc;
export const QUERY_WORKSPACE_EPIC_STATES_BY_STATE = QueryWorkspaceEpicStatesByStateDocument;
const INPUT = {
  pagination: {
    limit: 300,
    offset: 0
  },
  orderBy: [{
    value: 'position',
    direction: 'ascending'
  }],
  where: {}
};
const getEpicStates = edges => (edges || emptyArray).reduce((acc, {
  node
}) => {
  if (node.type) acc[node.type].push(node);
  return acc;
}, {
  unstarted: [],
  started: [],
  done: []
});
export function useEpicStatesByStateQuery() {
  const slug = useWorkspaceSlug();
  const {
    data,
    previousData,
    error
  } = useQuery(QUERY_WORKSPACE_EPIC_STATES_BY_STATE, {
    variables: {
      slug,
      input: INPUT
    }
  });
  const dataToRender = data || previousData;
  const epicStates = useMemo(() => getEpicStates(dataToRender?.workspace2?.epicStates?.edges || []), [dataToRender?.workspace2?.epicStates?.edges]);
  const isLoading = !error && !dataToRender;
  const isStale = !data && !!previousData;
  return {
    epicStates,
    pageInfo: dataToRender?.workspace2?.epicStates?.pageInfo,
    isLoading,
    isStale,
    error
  };
}
export function useLazyEpicStatesByStateQuery() {
  const slug = useWorkspaceSlug();
  const [executeQuery] = useLazyQuery(QUERY_WORKSPACE_EPIC_STATES_BY_STATE);
  const fetchEpicStates = useCallback(async () => {
    const {
      data,
      error
    } = await executeQuery({
      variables: {
        slug,
        input: INPUT
      }
    });
    const epicStates = getEpicStates(data?.workspace2?.epicStates?.edges);
    return {
      epicStates,
      pageInfo: data?.workspace2?.epicStates?.pageInfo,
      error,
      isLoading: false,
      isStatel: false
    };
  }, [executeQuery, slug]);
  return fetchEpicStates;
}