import "core-js/modules/es.array.push.js";
import { PAGE_NAMES, generatePathWithSlug, getPathForPage, navigateTo } from 'utils/navigation';
import { getHistory } from './history';

/**
 * Navigates to Docs.
 *
 * Navigating to Docs is a bit tricky because we use multiple histories,
 * meaning that in order to navigate to Docs, we need to leverage `navigateTo`,
 * but also to sync Docs' history so it knows what to render. We used to do a full reload
 * of the page to bypass this issue, but it's becoming quite tedious now that we have Docs
 * as a core feature (e.g., search & recently viewed).
 *
 * @example
 * ```ts
 * navigateToDocs();
 * ```
 *
 */
export function navigateToDocs() {
  const url = generatePathWithSlug(getPathForPage(PAGE_NAMES.WRITE));
  navigateTo({
    url: url
  });
  getHistory()?.push('/');
}