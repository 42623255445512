import { Icon } from '@clubhouse/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const DocScopeToIcon = {
  private: () => _jsx(DeprecatedIconAdapter, {
    width: 16,
    children: _jsx(Icon, {
      icon: "Lock",
      fill: "currentColor"
    })
  }),
  workspace: () => _jsx(DeprecatedIconAdapter, {
    width: 16,
    children: _jsx(Icon, {
      icon: "Document",
      fill: "currentColor"
    })
  }),
  shared: () => _jsx(DeprecatedIconAdapter, {
    width: 16,
    children: _jsx(Icon, {
      icon: "Document",
      fill: "currentColor"
    })
  })
};

// On docs-api, enum values are by convention `ANGRY_SNAKE_CASE` but on datalayer they are `calmCamelCase`,
// so we need to expect both cases here until we migrate everything to datalayer.
export function getDocIconForAccessControlScope(accessControlScope) {
  return DocScopeToIcon[accessControlScope.toLowerCase()];
}