import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { Switch } from '@clubhouse/shared/components/Switch';
import { Tooltip } from '@clubhouse/shared/components/Tooltip';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { ApplicationIds, update, useInstallationsByApplicationId } from 'data/entity/installation';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const TypeToApplicationId = {
  bitbucket: ApplicationIds.Bitbucket,
  github: ApplicationIds.Github,
  gitlab: ApplicationIds.Gitlab
};
export function EventHandlerToggle({
  type
}) {
  const isAdmin = isLoggedInUserAdmin();
  const installations = useInstallationsByApplicationId(TypeToApplicationId[type]);
  if (!installations?.length) return null;
  const isChecked = !installations[installations.length - 1].vcs_auto_move_stories_disabled;
  return _jsx(Tooltip, {
    content: "Only an admin can change this value.",
    disabled: isAdmin,
    children: _jsxs(Switch.Label, {
      children: [_jsx(Switch, {
        isDisabled: !isAdmin,
        isChecked: isChecked,
        onChange: isEnabled => {
          installations.forEach(installation => {
            if (!installation) return;
            update(installation, {
              vcs_auto_move_stories_disabled: !isEnabled
            });
          });
        }
      }), _jsxs(Text, {
        children: ["Automatically move a Story to the first ", _jsx("em", {
          children: "Started"
        }), " Workflow State when a Branch, Commit, or", ' ', type === 'gitlab' ? 'Merge Request' : 'Pull Request', "is associated with it."]
      })]
    })
  });
}
EventHandlerToggle.displayName = "EventHandlerToggle";