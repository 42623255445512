import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { useEffect } from 'react';
import Logo from '@clubhouse/assets/png/third-party-logos/bitbucket_logo.png';
import { Button } from '@clubhouse/shared/components/Button';
import { FixedSpacer, Spaced } from '@clubhouse/shared/components/Spaced';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { useToggleState } from '@clubhouse/shared/hooks';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import { fetchAll as fetchAllEvents } from 'data/entity/event';
import { ApplicationIds, useInstallationByApplicationId } from 'data/entity/installation';
import { getCurrentOrgId } from 'data/entity/organization';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { disableWebhook, enableWebhook, fetchAll as fetchAllWebhooks, getOrCreateBitbucket, useBitbucket } from 'data/entity/webhook';
import { EVENTS } from 'utils/monitoring';
import { PAGE_NAMES, generatePathForPage, getCurrentOrigin, getCurrentSlug, navigateOnClick, redirect } from 'utils/navigation';
import { sendEvent } from 'utils/segment';
import { CommentOnPrToggle } from './components/CommentOnPrToggle';
import { CreateEventDefinition } from './components/CreateEventDefinition';
import { EventDefinitionsTable } from './components/EventDefinitionsTable';
import { EventHandlerToggle } from './components/EventHandlerToggle';
import { ExternalLink } from './components/ExternalLink';
import { GitBranchFormatting } from './components/GitBranchFormatting';
import { IntegrationToggle } from './components/IntegrationToggle';
import { Layout } from './components/Layout';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const getDomain = () => {
  const path = `/integrations/bitbucket/${getCurrentOrgId()}`;
  const origin = getCurrentOrigin();
  const shortcutDomain = origin.match(/^https:\/\/(?:.*?\.|)(app\.shortcut(?:-[^.]+)?\.com)$/);
  if (shortcutDomain) return `https://bitbucket.${shortcutDomain[1]}${path}`;
  if (origin.startsWith('http://localhost:')) return `https://bitbucket.app.shortcut-staging.com${path}`;
  return null;
};
const getBitbucketUrl = () => {
  const baseURL = getDomain();
  const queryParams = {
    descriptor_uri: `${baseURL}/descriptor`,
    redirect_uri: `${baseURL}/signup-complete`
  };
  return `https://bitbucket.org/site/addons/authorize?${new URLSearchParams(queryParams).toString()}`;
};
export function Bitbucket() {
  const admin = isLoggedInUserAdmin();
  const [isReady, toggleReady] = useToggleState();
  const webhook = useBitbucket();
  const installation = useInstallationByApplicationId(ApplicationIds.Bitbucket);
  const isEnabled = !!webhook && webhook.disabled === false;
  const manageWorkflowUrl = generatePathForPage(PAGE_NAMES.SETTINGS_WORKFLOW, {
    slug: getCurrentSlug()
  });
  useEffect(() => {
    Promise.all([fetchAllWebhooks(), fetchAllEvents()]).then(() => getOrCreateBitbucket()).finally(toggleReady.on);
  }, [toggleReady.on]);
  return _jsxs(Layout, {
    children: [_jsx(Layout.Nav, {
      currentActive: INTEGRATION_TYPE.BITBUCKET
    }), _jsxs(Layout.Content, {
      children: [_jsx(Layout.Breadcrumbs, {
        name: "Bitbucket"
      }), _jsx(Layout.Logo, {
        src: Logo,
        alt: "Bitbucket Logo",
        invertOnDarkMode: true
      }), _jsxs(Layout.Blurb, {
        children: ["With our Bitbucket Cloud integration, you can link stories to commits, branches and pull requests, and use your Bitbucket workflow to move stories across your ", BRAND.NAME, " workflow."]
      }), _jsx(Layout.SectionTitle, {
        children: "Integration Status"
      }), isReady ? _jsx(IntegrationToggle, {
        isReadOnly: !admin,
        isEnabled: isEnabled,
        onChange: async newValue => {
          sendEvent(EVENTS.Interaction_IntegrationSettings, {
            integration_action: newValue ? 'on' : 'off',
            integration_type: 'bitbucket'
          });
          try {
            if (newValue) await enableWebhook(webhook);else await disableWebhook(webhook);
            addToast({
              kind: 'success',
              timeout: 5_000,
              Content: () => _jsxs(ToastText, {
                children: ["Integration ", newValue ? 'enabled' : 'disabled', "!"]
              })
            });
          } catch (e) {
            addToast({
              kind: 'alert',
              timeout: 10_000,
              Content: () => _jsxs(ToastText, {
                children: ["Unable to ", newValue ? 'enable' : 'disable', " integration."]
              })
            });
          }
        }
      }) : _jsx(IntegrationToggle.Loading, {}), admin && _jsxs(Spaced, {
        vertically: true,
        amount: "space6",
        overflow: "hidden",
        children: [!!webhook && _jsx(_Fragment, {
          children: !installation ? _jsx("div", {
            children: _jsx(Button, {
              onClick: () => {
                redirect(getBitbucketUrl());
              },
              children: "Connect to Bitbucket Cloud"
            })
          }) : _jsxs(Layout.Blurb, {
            children: ["Your ", BRAND.NAME, " Workspace is linked to the", ' ', _jsx(ExternalLink, {
              href: installation.bitbucket_account_url,
              children: installation.bitbucket_account_name
            }), ' ', "account."]
          })
        }), _jsx(GitBranchFormatting, {}), !!installation && isEnabled && _jsxs(_Fragment, {
          children: [_jsx(CommentOnPrToggle, {
            type: "bitbucket"
          }), _jsxs(Spaced, {
            vertically: true,
            amount: 0,
            children: [_jsx(Layout.SectionTitle, {
              children: "Bitbucket Event Handlers"
            }), _jsxs(Layout.HelpMessage, {
              noMargin: true,
              children: ["If you already use Pull Requests and Branches in Bitbucket, you can streamline your process by letting your Bitbucket workflow drive your", ' ', _jsxs("a", {
                href: manageWorkflowUrl,
                onClick: navigateOnClick(manageWorkflowUrl),
                children: [BRAND.NAME, " workflow"]
              })]
            }), _jsx(FixedSpacer, {
              size: "space3"
            }), _jsx(EventHandlerToggle, {
              type: "bitbucket"
            })]
          }), _jsxs(Spaced, {
            vertically: true,
            amount: "space2",
            children: [_jsx(EventDefinitionsTable, {
              type: "bitbucket"
            }), _jsx(CreateEventDefinition, {
              type: "bitbucket"
            })]
          })]
        })]
      })]
    })]
  });
}
Bitbucket.displayName = "Bitbucket";