import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { addToast, ToastText } from '@clubhouse/shared/components/Toast';
import { useToggleState } from '@clubhouse/shared/hooks';
import { getSelectedStories, isSelected } from 'data/entity/bulkSelection';
import { getById, setIteration } from 'data/entity/story';
import { useEffect, useRef } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function useDropTarget({
  iterationId
}) {
  const targetRef = useRef(null);
  const [isDraggingOver, toggleDraggingOver] = useToggleState();
  const [isSaving, toggleSaving] = useToggleState();
  useEffect(() => {
    const {
      current: targetEl
    } = targetRef;
    if (!targetEl) return;
    return dropTargetForElements({
      element: targetEl,
      canDrop({
        source
      }) {
        return !source.data.iterationId || source.data.iterationId !== iterationId;
      },
      getIsSticky() {
        return false;
      },
      getData() {
        return {
          iterationId
        };
      },
      onDragEnter({
        source
      }) {
        if (!source.data.iterationId || source.data.iterationId !== iterationId) toggleDraggingOver.on();
      },
      onDragLeave() {
        toggleDraggingOver.off();
      },
      onDrop({
        source
      }) {
        toggleDraggingOver.off();
        toggleSaving.on();
        const stories = isSelected({
          id: source.data.storyId
        }) ? getSelectedStories() : [source.data.storyId];
        const promises = stories.map(storyId => {
          const story = getById(storyId);
          if (!story) return Promise.resolve();
          return setIteration(story, Number(iterationId)).catch(err => {
            if (typeof err === 'string') addToast({
              kind: 'alert',
              timeout: 5000,
              Content: () => _jsx(ToastText, {
                children: err
              })
            });
          });
        });
        Promise.all(promises).finally(toggleSaving.off);
      }
    });
  }, [toggleDraggingOver, toggleSaving, iterationId]);
  return {
    ref: targetRef,
    isDraggingOver,
    isSaving
  };
}